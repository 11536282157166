//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { ar } from "vue2-datepicker/locale/ar";
import { en } from "vue2-datepicker/locale/en";
import * as moment from "moment";
//import { loadScript } from "vue-plugin-load-script";
export default {
    name: 'ContactUs',
    components: { DatePicker, ar, en },
    data: function () {
        let self = this;
        return {
            query: {
                Filter: [],
                PageNumber: 1,
                PageSize: 2,
            },
            baseUrl: process.env.VUE_APP_APIEndPoint,
            showValidMessage: false,
            successmsg: self.$t('savedSucess'),
            warnmesg: self.$t('warnmsg'),
            ViewMode: 1,
            model: {

            },
            filterModel: {
               // FindSharePriceDate: moment(new Date()).subtract(1, "day").locale("en").format('YYYY-MM-DD HH:mm')
            },
            IsCheck: false,
            showPopup: false,
            lastTenYearsSharePrice: {}
        }
    },
    methods: {
        ShowLoading: function () {
            $("body").addClass("active-loader");
        },
        HideLoading: function () {
            $("body").removeClass("active-loader");
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        onSubmit: function () {
            alert("Any thing");
            console.log("Any thing");
        },
        disableDate(date) {
            return date > new Date();
        },
        colsePopup: function () {
            this.showPopup = false;
        },

        showWedgit: function () {
            let self = this;
            self.IsCheck = true;
        },
        notify: function (message, fn = "error") {
            let self = this;
            self.$snotify.clear();
            if (fn == "success") {
                self.$snotify.success(message, {
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
            else if (fn == "warning") {
                self.$snotify.warning(message, {
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
            else {
                self.$snotify.html(message, {
                    type: "error",
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
        },
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        ShowLoading: function () {
            $("body").addClass("active-loader");
        },
        HideLoading: function () {
            $("body").removeClass("active-loader");
        },
        ConvertToJson: function (str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return "";
            }
            return JSON.parse(str);
        },
        displayError: function (err) {
            var self = this;
            if (err?.response?.status == "401") {
                return;
            }
            if (err?.response?.data?.type && err?.response?.data?.type.toLowerCase() == "business") {
                //let messages = err?.response?.data?.message;
                var messages = self.ConvertToJson(err?.response?.data?.message);
                if (messages != "") {
                    let errorstr = "";
                    messages.map(x => errorstr += self.$t(x.MemberNames) + ' ' + self.$t(x.ErrorMessage) + "<br/>");
                    self.notify(errorstr, "error")
                }
                else {
                    self.notify(self.$t("ConnectionError"), "error");
                }
            }
            else if (err?.response?.data?.type && err?.response?.data?.type.toLowerCase() == "error") {
                var errorMsg = err?.response?.data?.message;
                self.notify(self.$t(errorMsg[Object.keys(errorMsg)[0]]) + "<br/>" + err?.response?.data?.exCode, "error");
            }
            else if (err.length > 0) {
                for (let i = 0; i < err.length; i++) {
                    let errorstr = "";
                    errorstr += err[i].Message + "<br/>";
                    self.notify(errorstr, "error");
                }
            }
            else {
                self.notify(self.$t("ConnectionError"), "error");
            }
        },
        validateModel: function () {
            var self = this;
            var { Name, Email, PhoneNumber, Resume } = self.model
            return Name && Name.length && Email && Email.length && PhoneNumber && PhoneNumber.length && Resume;
        },
        ClearModel: function () {
            var self = this;
            self.model = {};
            self.showValidMessage = false;
            $('#uploader').val();
        },
        Submit: function () {
            let self = this;
            self.ShowLoading();
            self.filterModel.FindSharePriceDate = moment(self.filterModel.FindSharePriceDate).locale("en").format('YYYY-MM-DD HH:mm');
            debugger;
            self.axios
                .post(process.env.VUE_APP_APIEndPoint + "api/SharePriceInquiry/FindSharePrice", self.filterModel)
                .then(function (res) {
                    self.model = res.data.Data;
                    self.model.SharePriceDate = moment(self.model.SharePriceDate).locale(self.$i18n.locale == "ar" ? "ar-SA" : "en").format('LL');

                    self.showWedgit();
                }).catch(function (err) {
                    self.displayError(err);
                    return console.log(err);
                }).finally(function () {
                    return self.HideLoading();
                });
        },
        save: function () {
            let self = this;
            self.showPopup = true;
            self.showValidMessage = true;
            self.$validator.validate().then(function (result) {
                if (!result) { ////not valid
                    let detail = $('.form-error').first()[0];
                    if (detail) {
                        detail.scrollIntoView({ behavior: 'smooth', block: 'end' });
                        self.notify(self.$t('warnmsg'), "warning");

                    }
                    return;
                }
                $('.form-error').removeClass("form-error");
                self.Submit();

            });
        },
        load() {
            let self = this;
            self.ShowLoading();
            self.axios
                .get(process.env.VUE_APP_APIEndPoint + "api/SharePriceInquiry/GetLastTenYearsSharePrice")
                .then(function (res) {
                    var lastTenYearsSharePrice = res.data.Data;

                    var categories = lastTenYearsSharePrice.map((b) => b.Year.toString());
                    var data = lastTenYearsSharePrice.map((y, i) => { return { category: categories[i], y: y.MaxData } });

                    Highcharts.chart('containerlastTenYearsSharePrice', {
                        rangeSelector: {
                            enabled: false
                        },
                        stockTools: {
                            gui: {
                                enabled: false // disable the built-in toolbar
                            }
                        },
                        chart: {
                            type: 'column',
                            events: {
                                redraw: function () { }
                            }
                        },
                        tooltip: {
                            formatter: function () {
                                return '<b>' + this.y + '</b>';
                            },
                            useHTML: true
                        },
                        legend: { enabled: false },
                        title: {
                            text: self.$t("SASCO")
                        },
                        xAxis: {
                            categories: categories
                        },
                        yAxis: {
                            title: {
                                text: self.$t("SR")
                            }
                        },
                        series: [{
                            name: 'Sasco',
                            data: data,
                            type: 'column',
                        }]
                    });
                    self.HideLoading();
                });
        }


    },
    mounted() {
        let self = this;
        self.load();
    },
    watch: {
        '$i18n.locale'() {
            let self = this;
            self.load();
        }
    },

};
