//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { ar } from "vue2-datepicker/locale/ar";
import { en } from "vue2-datepicker/locale/en";
//import { loadScript } from "vue-plugin-load-script";
export default {
    name: 'ContactUs',
    components: { DatePicker },
    data: function () {
        let self = this;
        return {
            baseUrl: process.env.VUE_APP_APIEndPoint,
          //  ar: ar,
           // en: en,
            showValidMessage: false,
            CityList: [],
            SectionList: [],
            contactInfo: [],
            successmsg: self.$t('savedSucess'),
            warnmesg: self.$t('warnmsg'),
            countryList: [],
            NationalityList: [],
            ViewMode: 1,
            isbool: false,
            model: {
                OneCommercialFranchise: false
            },
            isLoading:false
        }
    },
    methods: {
        loadLookups: function () {
            Promise.all([this.loadCountry(), this.loadNationality()]);
        },

        checkVal(val) {
            let self = this;
            // self.isbool = !self.isbool;
            if (val == true) self.isbool = true;
            else self.isbool = false;
        },

        loadCountry: function () {
            var self = this;
            self.axios
                .get(process.env.VUE_APP_APIEndPoint + "api/Country/PublicGetAll")
                .then((response) => {
                    self.countryList = response.data.Data;
                    setTimeout(function () { }, 100);
                });
        },


        loadNationality: function () {
            var self = this;
            self.axios
                .get(process.env.VUE_APP_APIEndPoint + "api/FranchiseApplication/GetAllNationality")
                .then((response) => {
                    self.NationalityList = response.data.Data;
                    setTimeout(function () { }, 100);
                });
        },


        scrollToTop() {
            window.scrollTo(0, 0);
        },
        onSubmit: function () {
            alert("Any thing");
            console.log("Any thing");
        },


        notify: function (message, fn = "error") {
            let self = this;
            self.$snotify.clear();
            if (fn == "success") {
                self.$snotify.success(message, {
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
            else if (fn == "warning") {
                self.$snotify.warning(message, {
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
            else {
                self.$snotify.html(message, {
                    type: "error",
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
        },
        onlyNumber($event) {
            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
       
        OnlyCharctersandSpace(e) {

            var regex = new RegExp("^[a-zA-Z\u0600-\u06FF ]+$");

            var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
            if (regex.test(str)) {
                return true;
            }
            e.preventDefault();
            return false;
        },

        OnlyCharctersandNumbersandSpace(e) {
            var regex = new RegExp("^[0-9a-zA-Z\u0600-\u06FF ]+$");
            var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
            if (regex.test(str)) {
                return true;
            }
            e.preventDefault();
            return false;
        },

        OnlyNumbersandSpace(e) {
            var regex = new RegExp("^[0-9\u0600-\u06FF ]+$");
            var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
            if (regex.test(str)) {
                return true;
            }
            e.preventDefault();
            return false;
        },

        OnlySpechialCharctersandNumbers(e) {
            var regex = new RegExp("^[0-9\-\u06FF!@#$%^&*<>?~/]+$");
            var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
            if (regex.test(str)) {
                return true;
            }

            e.preventDefault();
            return false;


        },
        OnlyCharctersandSpechialCharctersandNumbers(e) {
            var regex = new RegExp("^[0-9a-zA-Z\-\u06FF!@#$%^&*<>?~/]+$");
            var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
            if (regex.test(str)) {
                return true;
            }

            e.preventDefault();
            return false;


        },
        
        validateModel: function () {
            var self = this;
            var { Name, Email, PhoneNumber, Resume } = self.model
            return Name && Name.length && Email && Email.length && PhoneNumber && PhoneNumber.length && Resume;
        },
        ClearModel: function () {
            var self = this;
            self.model = {};
            self.showValidMessage = false;
            $('#uploader').val();
        },
        ShowLoading: function () {
            $("body").addClass("active-loader");               
        },
        HideLoading: function () {
            $("body").removeClass("active-loader");                
        },
        Submit: function () {
            let self = this;
            self.ShowLoading();
            debugger;
            // var uploadheaders = { "Content-Type": "multipart/form-data" };
            //var { Name, Email, PhoneNumber, Resume } = self.model
            //var { FullName, IdNumber, PhoneNumber, Email, ShareholderNumber, CertificateNumber, HafidaNumber, DistributionDate } = self.model
            //var model = new FormData();
            //model.append('FullName', FullName);
            //model.append('IdNumber', IdNumber);
            //model.append('PhoneNumber', PhoneNumber);
            //model.append('Email', Email);
            //model.append('ShareholderNumber', ShareholderNumber);
            //model.append('CertificateNumber', CertificateNumber);
            //model.append('HafidaNumber', HafidaNumber);
            //model.append('DistributionDate', DistributionDate);


            
            self.axios
                .post(process.env.VUE_APP_APIEndPoint + "api/FranchiseApplication/Add", self.model)
                .then(function (res) {
                    self.HideLoading();
                    self.notify(self.$t('savedSucess'), "success");
                    self.ClearModel();
                    self.scrollToTop();

                });
        },
        formatDate(value) {
            if (localStorage.lang == 'ar') {
                let date = new Date(value);
                const day = date.toLocaleString("default", { day: "2-digit" });
                const month = date.toLocaleString("ar-EG-u-nu-latn", { month: "short" });
                const year = date.toLocaleString("default", { year: "numeric" });
                return day + " " + month + " " + year;
            }

            else if (localStorage.lang == 'en') {
                let date = new Date(value);
                const day = date.toLocaleString("default", { day: "2-digit" });
                const month = date.toLocaleString("default", { month: "short" });
                const year = date.toLocaleString("default", { year: "numeric" });
                return day + " " + month + " " + year;
            }

        },
        save: function () {
            let self = this;
            debugger;
            self.showValidMessage = true;
            self.$validator.validate().then(function (result) {
                if (!result) { ////not valid
                    let detail = $('.form-error').first()[0];
                    if (detail) {
                        detail.scrollIntoView({ behavior: 'smooth', block: 'end' });
                        self.notify(self.$t('warnmsg'), "warning");

                    }
                    return;
                }
                $('.form-error').removeClass("form-error");
                self.Submit();


            });
        },

    },
    //datee: function () {
    //    if (self.Filter.DistributionDate && self.Filter.DistributionDate != "") {
    //        self.query.Filter.push({
    //            FieldName: "DistributionDate",
    //            Operation: "=",
    //            value: self.Filter.DistributionDate,
    //        });
    //    }
    //},
    created: function () {
        var self = this;

    },
    mounted() {
        let self = this;
     // self.$validator.localize(`${self.$i18n.locale}`, self.$i18n.locale == 'en' ? self.dictionary.en : self.dictionary.ar);
        self.loadCountry();
        self.loadNationality();
    }
};
