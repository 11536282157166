//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

    export default {
        name: "SubscribeNewsletter",
    data: function() {
     let self = this;
    return {
        dictionary: {
            en: {
                messages: {
                    required: function required(fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('required');
                    },
                    email: function email() {
                        return 'Invalid Email Format';
                    },
                    max: function max(fieldName, numbers) {
                        return self.$t(fieldName) + self.$t('max') + " " + numbers + " " + self.$t('numbers');
                    }, min: function min(fieldName, numbers) {

                        return self.$t(fieldName) + " " + self.$t('min') + " " + numbers + " " + self.$t('numbers');
                    },
                    alpha: function alpha_spaces(fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('alphaSpaces');
                    },
                    //between: function between(fieldName, n1, n2) {
                    //  return self.$t(fieldName) + ' ' + n1 + ' ' + n2;
                    //},
                    confirmed: function confirmed(fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('NotConfirmed');
                    },
                    regex: function regex(fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('NotMatchPasswordPattern');
                    },

                    between: function between(fieldName, n) {
                        return self.$t(fieldName) + ' ' + ' Should be Between ' + n[0] + ' and ' + n[1];
                    },
                    numeric: function (fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('should be Number');
                    },
                    verify_password: function (fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('ComplexPassword');
                    },
                    mobileReg: function (fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('Number');
                    }
                }
            },
            ar: {
                messages: {
                    required: function required(fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('required');
                    },
                    email: function email() {
                        return 'تنسيق بريد إلكتروني غير صالح';
                    },
                    max: function max(fieldName, numbers) {
                        return self.$t(fieldName) + self.$t('max') + numbers + self.$t('numbers');
                    }, min: function min(fieldName, numbers) {

                        return self.$t(fieldName) + " " + self.$t('min') + " " + numbers + " " + self.$t('numbers');
                    },
                    alpha: function alpha_spaces(fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('shouldBeCharOnly');
                    },
                  
                    confirmed: function confirmed(fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('NotConfirmed');
                    },
                    regex: function regex(fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('NotMatchPasswordPattern');
                    },
                    between: function between(fieldName, n) {
                        return self.$t(fieldName) + ' ' + 'يجب ان يكون بين ' + n[0] + 'و' + n[1];
                    },
                    numeric: function (fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('should be Number');
                    },
                    verify_password: function (fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('ComplexPassword');
                    },
                    mobileReg: function (fieldName) {
                        return self.$t(fieldName) + ' ' + self.$t('Number');
                    }
                }
            }

        },
        model: {},
        showValidMessage: false,
        //successmsg: self.$t('SubscriptionSucces'),
        //Alreadymsg: self.$t('SubscriptionAlready'),
        //warnmesg: self.$t('warnmsgEmail'),
    };
  },
  methods: {
      notify: function (message, fn = "error") {
          let self = this;
          self.$snotify.clear();
          if (fn == "success") {
              self.$snotify.success(message, {
                  timeout: 3000,
                  showProgressBar: true,
                  //closeOnClick: false,
                  pauseOnHover: true
              });
          }
          else if (fn == "warning") {
              self.$snotify.warning(message, {
                  timeout: 3000,
                  showProgressBar: true,
                  //closeOnClick: false,
                  pauseOnHover: true
              });
          }
          else {
              self.$snotify.html(message, {
                  type: "error",
                  timeout: 3000,
                  showProgressBar: true,
                  //closeOnClick: false,
                  pauseOnHover: true
              });
          }
      },
      ClearModel: function () {
          var self = this;
          self.model = {};
          self.showValidMessage = false;
      },
      Submit: function () {
          var self = this;
          self.axios
              .post(process.env.VUE_APP_APIEndPoint + "api/NewsLetterSubscription/Add", self.model)
              .then(function (res) {
                  if (res.data.IsSuccess == true) {
                      self.notify(self.$i18n.locale == "ar" ? "تم الاشتراك بنجاح" : "Subscription Succes", "success");
                      self.ClearModel();
                  }
                  else {
                      self.notify(self.$i18n.locale == "ar" ? "أنت مشترك بالفعل" : "Subscription Already", "success");
                      self.ClearModel();
                  }
                  
              });
      },
      save: function () {
          let self = this;
          self.showValidMessage = true;
          self.$validator.validate().then(function (result) {
              if (!result) { ////not valid
                  let detail = $('.form-error').first()[0];
                  if (detail) {
                      //detail.scrollIntoView({ behavior: 'smooth', block: 'end' });
                      self.notify(self.$i18n.locale == "ar" ? "من فضلك أدخل البريد الالكتروني" : "Please enter your email", "warning");

                  }
                  return;
              }
              $('.form-error').removeClass("form-error");
              self.Submit();


          });
      },
  },

  mounted() {
      let self = this;
      this.$validator.localize(`${self.$i18n.locale}`, self.$i18n.locale == 'en' ? this.dictionary.en : this.dictionary.ar);
   
  },
  updated() {
  },
};
