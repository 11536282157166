//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import EmptyState from '@/components/shared/EmptyState.vue'
export default {
    name: 'Multimedia',
    components: { EmptyState },
    data: function () {
        return {
            isCheck: false,
            isLoading: false,
            lang: localStorage.lang,
            total_pages: 0,
            total_rows: 0,
            modelList: [],
            query: {
                Filter: [],
                PageNumber: 1,
                PageSize: process.env.VUE_APP_Default_Page_Size,
            },
            Filter: {},
        };
    },
    methods: {
        load() {
            let self = this;
            if (!self.isLoading && !self.isCheck) {
                self.isLoading = true;
                self.axios
                    .post(process.env.VUE_APP_APIEndPoint + "api/Multimedia/PublicGetAll", self.query)
                    .then(function (res) {
                        self.modelList = [...self.modelList, ...res.data.Data];
                        self.query.PageNumber = res.data.PageNumber;
                        self.query.PageSize = res.data.PageSize;
                        self.total_rows = res.data.Total;
                        self.total_pages = Math.ceil(self.total_rows / res.data.PageSize);
                        if (self.total_pages > 0 && self.query.PageNumber <= 1) {
                            setTimeout(function () { }, 100);
                        }
                        self.isCheck = false;
                        if (self.total_pages <= self.query.PageNumber) self.isCheck = true;

                    })
                    .finally(() => self.isLoading = false);
            }

        },
        Scroll() {
            let self = this;
            self.query.PageNumber++;
            self.load();
        },
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    updated() {
        initGallerySlider();
    },
    mounted() {
        let self = this;
        self.load();
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    },
}
