//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { ar } from "vue2-datepicker/locale/ar";
import { en } from "vue2-datepicker/locale/en";
//import { loadScript } from "vue-plugin-load-script";
export default {
    name: 'ContactUs',
    components: { DatePicker },
    data: function () {
        let self = this;
        return {
            ar: ar,
            en: en,
            dictionary: {
                en: {
                    messages: {
                        required: function required(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('required');
                        },
                        email: function email() {
                            return 'Invalid Email Format';
                        },
                      
                        length: function length() {
                           // return 'The Certificate Number Must Not Be Less Than 7 Digits';
                        },
                       
                        max: function max(fieldName, numbers) {
                            return self.$t(fieldName) + self.$t('max') + " " + numbers + " " + self.$t('numbers');
                        }, min: function min(fieldName, numbers) {

                            return self.$t(fieldName) + " " + self.$t('min') + " " + numbers + " " + self.$t('numbers');
                        },
                        alpha: function alpha_spaces(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('alphaSpaces');
                        },
                        length: function length() {
                            return 'The IdentityNumber Must Not Be Less Than 10 Digits';
                            // return self.$t(fieldName) + ' ' + self.$t('Certificate Number Must');
                        },
                        length: function length() {
                            return 'The shareholderNumber Must Not Be Less Than 10 Digits';
                            // return self.$t(fieldName) + ' ' + self.$t('Certificate Number Must');
                        },
                        length: function length() {
                            return 'The HafidaNumber Must Not Be Less Than 10 Digits';
                            // return self.$t(fieldName) + ' ' + self.$t('Certificate Number Must');
                        },
                        //between: function between(fieldName, n1, n2) {
                        //  return self.$t(fieldName) + ' ' + n1 + ' ' + n2;
                        //},
                        confirmed: function confirmed(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NotConfirmed');
                        },
                        regex: function regex(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NotMatchPasswordPattern');
                        },

                        between: function between(fieldName, n) {
                            return self.$t(fieldName) + ' ' + ' Should be Between ' + n[0] + ' and ' + n[1];
                        },
                        numeric: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('should be Number');
                        },
                        verify_password: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('ComplexPassword');
                        },
                        mobileReg: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NumberReg');
                        }
                    }
                },
                ar: {
                    messages: {
                        required: function required(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('required');
                        },
                        email: function email() {
                            return 'تنسيق البريد الالكتروني غير صالح';
                        },
                        max: function max(fieldName, numbers) {
                            return self.$t(fieldName) + self.$t('max') + numbers + self.$t('numbers');
                        }, min: function min(fieldName, numbers) {

                            return self.$t(fieldName) + " " + self.$t('min') + " " + numbers + " " + self.$t('numbers');
                        },
                        alpha: function alpha_spaces(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('shouldBeCharOnly');
                        },
                        length: function length() {
                            return 'رقم الشهاده لايقل عن 7 أرقام';
                        },
                        length: function length() {
                            return 'رقم الهوية لايقل عن 10 أرقام';
                            // return self.$t(fieldName) + ' ' + self.$t('Certificate Number Must');
                        },
                        length: function length() {
                            return 'رقم المساهم لايقل عن 10 أرقام';
                            // return self.$t(fieldName) + ' ' + self.$t('Certificate Number Must');
                        },
                        length: function length() {
                            return 'رقم الحفيظة لايقل عن 10 أرقام';
                            // return self.$t(fieldName) + ' ' + self.$t('Certificate Number Must');
                        },
                        //between: function between(fieldName, n) {
                        //     return self.$t(fieldName) + self.$t('min') + n[0] + self.$t('max') + n[1];
                        //},
                        confirmed: function confirmed(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NotConfirmed');
                        },
                        regex: function regex(fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NotMatchPasswordPattern');
                        },
                        between: function between(fieldName, n) {
                            return self.$t(fieldName) + ' ' + '??? ?? ???? ??? ' + n[0] + '?' + n[1];
                        },
                        numeric: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('should be Number');
                        },
                        verify_password: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('ComplexPassword');
                        },
                        mobileReg: function (fieldName) {
                            return self.$t(fieldName) + ' ' + self.$t('NumberReg');
                        }
                    }
                }

            },
            model: {},
            showValidMessage: false,
            CityList: [],
            SectionList: [],
            contactInfo: [],
            successmsg: self.$t('savedSucess'),
            warnmesg: self.$t('warnmsg'),
            isActive: true,
            //rcapt_sig_key: "HERE LIVE GOOGLE API KEY",
            //live_google_recpachid: 0
        }
    },
    methods: {


        disableDate(date) {
            return date > new Date();
        },
        scrollToTop() {
            window.scrollTo(0, 0);
        },
        onSubmit: function () {
            alert("Any thing");
            console.log("Any thing");
        },

        //upload: function (event) {
        //    var self = this;
        //    var file = event.target.files[0];
        //    var errMsg = null;
        //    if (!file)
        //        errMsg = "FileNotFound";
        //    else if (file.size > 5 * 1024 * 1024)
        //        errMsg = "LargeFile";
        //    else if (file.type != 'application/pdf')
        //        errMsg = "FileMustBePdf";

        //    if (errMsg) {
        //        $('#uploader').val(null);
        //        self.notify(self.$t(errMsg), "warning");
        //        return;
        //    }
        //    self.model.Resume = file;
        //},

        notify: function (message, fn = "error") {
            let self = this;
            self.$snotify.clear();
            if (fn == "success") {
                self.$snotify.success(message, {
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
            else if (fn == "warning") {
                self.$snotify.warning(message, {
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
            else {
                self.$snotify.html(message, {
                    type: "error",
                    timeout: 3000,
                    showProgressBar: true,
                    //closeOnClick: false,
                    pauseOnHover: true
                });
            }
        },
        onlyNumber($event) {

            let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
            if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
                $event.preventDefault();
            }
        },
        validateModel: function () {
            var self = this;
            var { Name, Email, PhoneNumber, IdNumber, shareholderNumber, HafidaNumber, CertificateNumber } = self.model
            return Name && Name.length && Email && Email.length && PhoneNumber && PhoneNumber.length && IdNumber.length &&
                shareholderNumber.length && HafidaNumber.length && CertificateNumber.length;
        },
        ClearModel: function () {
            var self = this;
            self.model = {};
            self.showValidMessage = false;
            $('#uploader').val();
        },
        Submit: function () {
            let self = this;

            debugger;
           // var uploadheaders = { "Content-Type": "multipart/form-data" };
            //var { Name, Email, PhoneNumber, Resume } = self.model
            //var { FullName, IdNumber, PhoneNumber, Email, ShareholderNumber, CertificateNumber, HafidaNumber, DistributionDate } = self.model
            //var model = new FormData();
            //model.append('FullName', FullName);
            //model.append('IdNumber', IdNumber);
            //model.append('PhoneNumber', PhoneNumber);
            //model.append('Email', Email);
            //model.append('ShareholderNumber', ShareholderNumber);
            //model.append('CertificateNumber', CertificateNumber);
            //model.append('HafidaNumber', HafidaNumber);
            //model.append('DistributionDate', DistributionDate);


            self.axios
                .post(process.env.VUE_APP_APIEndPoint + "api/InvestmentCertificateRequest/AddInvestmentCertificateRequest", self.model)
                .then(function (res) {
                    self.notify(self.$t('savedSucess'), "success");
                    self.ClearModel();
                    self.scrollToTop();
                });
        },
        formatDate(value) {
            if (localStorage.lang == 'ar') {
                let date = new Date(value);
                const day = date.toLocaleString("default", { day: "2-digit" });
                const month = date.toLocaleString("ar-EG-u-nu-latn", { month: "short" });
                const year = date.toLocaleString("default", { year: "numeric" });
                return day + " " + month + " " + year;
            }

            else if (localStorage.lang == 'en') {
                let date = new Date(value);
                const day = date.toLocaleString("default", { day: "2-digit" });
                const month = date.toLocaleString("default", { month: "short" });
                const year = date.toLocaleString("default", { year: "numeric" });
                return day + " " + month + " " + year;
            }

        },
        save: function () {
            let self = this;
            self.showValidMessage = true;
            self.$validator.validate().then(function (result) {
                if (!result) { ////not valid
                    let detail = $('.form-error').first()[0];
                    if (detail) {
                        detail.scrollIntoView({ behavior: 'smooth', block: 'end' });
                        self.notify(self.$t('warnmsg'), "warning");

                    }
                    return;
                }
                $('.form-error').removeClass("form-error");
                self.Submit();


            });
        },

    },
    //datee: function () {
    //    if (self.Filter.DistributionDate && self.Filter.DistributionDate != "") {
    //        self.query.Filter.push({
    //            FieldName: "DistributionDate",
    //            Operation: "=",
    //            value: self.Filter.DistributionDate,
    //        });
    //    }
    //},
    created: function () {
        var self = this;

    },
    mounted() {
        let self = this;
        this.$validator.localize(`${self.$i18n.locale}`, self.$i18n.locale == 'en' ? this.dictionary.en : this.dictionary.ar);

    }
};
