//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: "LatestNews",
  data() {
    return {
        LatestadviList: [],
        isLoading: true,
    };
  },
  methods: {
    formatDate(value) {
      if (localStorage.lang == "ar") {
        let date = new Date(value);
        const day = date.toLocaleString("default", { day: "2-digit" });
        const month = date.toLocaleString("ar-EG-u-nu-latn", {
          month: "short",
        });
        const year = date.toLocaleString("default", { year: "numeric" });
        //   return  days[date.getDay()] + " - " + day + " - " + month + " - " + year;
        return day + " - " + month + " - " + year;
      } else if (localStorage.lang == "en") {
        let date = new Date(value);
        const day = date.toLocaleString("default", { day: "2-digit" });
        const month = date.toLocaleString("default", { month: "short" });
        const year = date.toLocaleString("default", { year: "numeric" });
        return day + " - " + month + " - " + year;
      }
    },
  },

  mounted() {
    let self = this;
    self.axios
        .get(process.env.VUE_APP_APIEndPoint + "api/Advertisement/GetLatest")
      .then((response) => {
          self.LatestadviList = response.data.Data;
      })
      .finally(() => self.isLoading = false);
  },
  updated() {
      homeNewsSliderInit();
  },
};
