//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'Hero',
    data() {
        return {
            items: [],
            isLoading: true,
        }
    },
    mounted() {
        let self = this;
        self.axios
            .get(process.env.VUE_APP_APIEndPoint + 'api/Banner/PublicGetAll')
            .then(response => {
                self.items = response.data.Data.filter(item => item.PhotoAttachment);
            })
            .finally(
                () => self.isLoading = false
            );
    },
    updated() {
        heroSliderInit();
    }
};

