//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { EventBus } from '@/main.js'

export default {
    name: "SearchContent",
    data() {
        return {
            lang: localStorage.lang,
            query: {
                Filter: [],
                PageNumber: 1,
                PageSize: process.env.VUE_APP_Default_Page_Size,
            },
            Filter: {},
            isLoading: false,
            matchedContentList: []
        };
    },
    methods: {

        Search: function () {
            //debugger;

            let self = this;
            self.query.Filter = [];
            if (self.Filter.Title && self.Filter.Title != "") {
                self.query.Filter.push({
                    FieldName: "Title",
                    Operation: "=",
                    value: self.Filter.Title,
                });
            }

            self.query.PageNumber = 1;
            self.query.PageSize = process.env.VUE_APP_Default_Page_Size;

            this.$router.push("/SearchContentResult").catch(() => { }).finally(() => EventBus.$emit('searchValue', self.query));
        },
        formatDate(value) {
            if (localStorage.lang == 'ar') {
                let date = new Date(value);
                const day = date.toLocaleString("default", { day: "2-digit" });
                const month = date.toLocaleString("ar-EG-u-nu-latn", { month: "short" });
                const year = date.toLocaleString("default", { year: "numeric" });
                return day + " " + month + " " + year;
            }

            else if (localStorage.lang == 'en') {
                let date = new Date(value);
                const day = date.toLocaleString("default", { day: "2-digit" });
                const month = date.toLocaleString("default", { month: "short" });
                const year = date.toLocaleString("default", { year: "numeric" });
                return day + " " + month + " " + year;
            }
        },
        autocomplete() {
            debugger;
            let self = this;
            self.query.Filter = [];
            if (self.Filter.Title && self.Filter.Title != "") {
                self.query.Filter.push({
                    FieldName: "Title",
                    Operation: "=",
                    value: self.Filter.Title,
                });
            }

            self.query.PageNumber = 1;
            self.query.PageSize = process.env.VUE_APP_Default_Page_Size;

            if (!self.isLoading) {
                self.isLoading = true;
                self.axios
                    .post(process.env.VUE_APP_APIEndPoint + "api/SearchContent/PublicGetAll", self.query)
                    .then(function (res) {
                        if (res.data.Data) {
                            self.matchedContentList = res.data.Data;
                        }
                        else {
                            self.matchedContentList = [];
                        }

                        self.query.PageNumber = res.data.PageNumber;
                        self.query.PageSize = res.data.PageSize;
                        self.total_rows = res.data.Total;
                        self.total_pages = Math.ceil(self.total_rows / res.data.PageSize);
                        if (self.total_pages > 0 && self.query.PageNumber <= 1) {
                            setTimeout(function () { }, 100);
                        }
                    })
                    .finally(() => self.isLoading = false);
            }
        },

        OnlyCharctersandNumbersandSpace(e) {

            var regex = new RegExp("^[0-9a-zA-Z\u0600-\u06FF ]+$");

            var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
            if (regex.test(str)) {
                return true;
            }



            e.preventDefault();
            return false;

        },

        //stopProp(e) {
        //    e.stopPropagation();
        //}
    },
    //mounted() {
    //    let searchDropdown = document.querySelector('.search-box--suggestion-wrapper');

    //    document.addEventListener('click', function (e) {
    //        document.body.classList.remove("active");
    //        document.body.classList.add("not-active");
    //    })
    //    searchDropdown?.addEventListener('click', function (e) {
    //        e.stopPropagation();
    //    })
    //}

};
