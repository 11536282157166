//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: 'NewsDetails',
    data() {
        return {
            NewsDetailsList: {
                PublishDate: "",
                LinkTitle: "",
                AdvTitle: "",
                //ContentAr: "",
                //ContentEn: "",
                //NewsCategory: {
                //    NameAr: "",
                //    NameEn: ""
                //},
                //PhotoAttachmentAr: {

                //},
                //PhotoAttachmentEn: {

                //}
            },
            //NewsByCategoryIdList: [],
        }
    },
    methods: {
        formatDate(value) {
            if (localStorage.lang == 'ar') {
                let date = new Date(value);
                const day = date.toLocaleString("default", { day: "2-digit" });
                const month = date.toLocaleString("ar-EG-u-nu-latn", { month: "short" });
                const year = date.toLocaleString("default", { year: "numeric" });
                //   return  days[date.getDay()] + " - " + day + " - " + month + " - " + year;
                return day + " - " + month + " - " + year;
            }

            else if (localStorage.lang == 'en') {
                let date = new Date(value);
                const day = date.toLocaleString("default", { day: "2-digit" });
                const month = date.toLocaleString("default", { month: "short" });
                const year = date.toLocaleString("default", { year: "numeric" });
                return day + " - " + month + " - " + year;
            }

        },
        loadData() {
            let self = this;
            self.axios
                .get(process.env.VUE_APP_APIEndPoint + 'api/Advertisement/GetAdvertisementById/' + self.$route.params?.id)
                .then(response => {
                    self.NewsDetailsList = response.data.Data;
                   
                });
        }
    },
    mounted() {
        this.loadData();
    },
    watch: {
        $route() {
            this.loadData();
        }
        //"$route.params?.id":function() {
        //    this.loadData();

        //}
    },
    updated() {
        homeNewsSliderInit();
    }
}
