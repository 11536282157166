//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

//https://www.digitalocean.com/community/tutorials/vuejs-vue-google-maps
    //https://www.npmjs.com/package/vue2-google-maps
    //import { gmapApi } from 'vue2-google-maps'
    //import { loadScript } from "vue-plugin-load-script";


    export default {
        name: 'InteractiveMap',
        props: ['defaultFilter'],
        data() {
            return {
                center: { lat: 25.357551713821774, lng: 46.696624717990716 },
                isLoading: true,
                markers: [],
                RegionList: [],
                AllMapLocationList:[],
                MapLocationList: [],
                DMapLocationList:[],
                infowindow: null,
                showMapPopup: false,
                map: null,
                SelectedRegion: 0,
                SelectedLocation: 0,
                SelectedLocationType: 0,
                Gmarkers:[],
            }
        },
        methods: {
            openDlg: function (pos) {
                let self = this;
                
                //self.showMapPopup = true;
                
                //let e = window.event;

                //let posX = e.pageX;
                //let posY = e.clientY;
                //self.$refs.MapPopup.style.top = posY+"px";
                //self.$refs.MapPopup.style.left = posX + "px";

                self.infowindow = new google.maps.InfoWindow();
                self.infowindow.setContent(pos.title || "");
                self.infowindow.open(self.map);
            },
            //geolocate: function () {
            //    navigator.geolocation.getCurrentPosition(position => {
            //        this.center = {
            //            lat: position.coords.latitude,
            //            lng: position.coords.longitude,
            //        };
            //    });
            //},
            loadMarkers: function () {
                debugger;
                let self = this;
                
                for (let i = 0; i < self.Gmarkers.length; i++) {
                    self.Gmarkers[i].setMap(null);
                }
                self.Gmarkers = [];

                const image = "/assets/images/pin.png";
                const image3 = "/assets/images/carwash.png";
                const image4 = "/assets/images/palm.png";
                const image5 = "/assets/images/naft.png";
                 /*= "/assets/images/pin.png";   */
                               
                                             
                self.markers = self.MapLocationList.map(x => {

                    if (x.LocationType == 1 || x.LocationType == 2) {
                        let d = {
                            position: { lat: Number(x.Latitude), lng: Number(x.Longitude) },
                            Icon: image,
                            title: self.$i18n.locale == 'ar' ? x.LocationNameAr : x.LocationNameEn,
                            Description: self.$i18n.locale == 'ar' ? x.LocationDescriptionAr : x.LocationDescriptionEn,
                            Photo: x?.LocationPhoto?.FilePath
                        };

                        return d
                    }

                    if (x.LocationType == 3) {
                        let d = {
                            position: { lat: Number(x.Latitude), lng: Number(x.Longitude) },
                            Icon: image3,
                            title: self.$i18n.locale == 'ar' ? x.LocationNameAr : x.LocationNameEn,
                            Description: self.$i18n.locale == 'ar' ? x.LocationDescriptionAr : x.LocationDescriptionEn,
                            Photo: x?.LocationPhoto?.FilePath
                        };

                        return d
                    }

                    if (x.LocationType == 4) {
                        let d = {
                            position: { lat: Number(x.Latitude), lng: Number(x.Longitude) },
                            Icon: image4,
                            title: self.$i18n.locale == 'ar' ? x.LocationNameAr : x.LocationNameEn,
                            Description: self.$i18n.locale == 'ar' ? x.LocationDescriptionAr : x.LocationDescriptionEn,
                            Photo: x?.LocationPhoto?.FilePath
                        };

                        return d
                    }

                    if (x.LocationType == 5) {
                        let d = {
                            position: { lat: Number(x.Latitude), lng: Number(x.Longitude) },
                            Icon: image5,
                            title: self.$i18n.locale == 'ar' ? x.LocationNameAr : x.LocationNameEn,
                            Description: self.$i18n.locale == 'ar' ? x.LocationDescriptionAr : x.LocationDescriptionEn,
                            Photo: x?.LocationPhoto?.FilePath
                        };

                        return d
                    }
                   
                });

                /*.LocationDescriptionEn*/

                let infowindow = new google.maps.InfoWindow({
                    content:'',
                });
                let position = new google.maps.LatLng(self.markers[0].position.lat, self.markers[0].position.lng);
                self.map.setCenter(position);

                for (let i = 0; i < self.markers.length; i++) {
                    let mm = new google.maps.Marker({
                        position: self.markers[i].position,
                        map: self.map,
                        Icon: self.markers[i].Icon,
                        title: self.markers[i].title,
                        Description: self.markers[i].Description,
                        Photo: self.markers[i].Photo
                    });

                    

                    mm.addListener("click", () => {

                        let content = `<div>
                                        <img style="width: 100%;
                                                    height: auto;
                                                    max-height: 150px;
                                                    object-fit: cover;
                                                    object-position: center;
                                                    border-radius: 8px;
                                                    margin-bottom: .5rem;"
                                             src="${mm.Photo}" alt="category image">
                                        <h3 style="color:#000;font-size: 1.25rem;margin-bottom: .5rem;line-height: 1.5;">${mm.title}</h3>
                                        <div style="color:#000;font-size: .75rem;">
                                             ${mm.Description}
                                        </div>
                                    </div>`

                        infowindow.setContent(content);
                        infowindow.open({
                             anchor: mm,
                             map: self.map,
                             //shouldFocus: false,
                         });
                    });
                    self.Gmarkers.push(mm);
                         
                }

            },           
            MapFilter: function () {
                let self = this;
                self.DMapLocationList = JSON.parse(JSON.stringify(self.AllMapLocationList));
                if (self.SelectedRegion != 0) {
                    self.DMapLocationList = self.AllMapLocationList.filter(x => x.RegionId == self.SelectedRegion);
                }

                self.MapLocationList = JSON.parse(JSON.stringify(self.AllMapLocationList));
                self.MapLocationList = self.AllMapLocationList.filter(x => 
                    (self.SelectedRegion == 0 && self.SelectedLocation == 0 && self.SelectedLocationType == 0)
                    || (
                        (self.SelectedRegion == 0 || (self.SelectedRegion != 0 && x.RegionId == self.SelectedRegion))
                        && (self.SelectedLocation==0|| (self.SelectedLocation != 0 && x.Id == self.SelectedLocation))
                        && (self.SelectedLocationType==0 || (self.SelectedLocationType != 0 && x.LocationType == self.SelectedLocationType)))
                    
                    //if (self.SelectedRegion!=0) {
                    //    //x.RegionId == self.SelectedRegion
                    //    self.SelectedLocation = 0
                    //}
                    //if (self.SelectedLocation != 0) {
                    //    x.Id == self.SelectedLocation
                    //}
                    //if (self.SelectedLocationType != 0) {
                    //    x.LocationType == self.SelectedLocationType
                    //}
                )

             
                self.loadMarkers();
            },
            LoadRegions: function () {
                let self = this;
                self.axios
                    .get(process.env.VUE_APP_APIEndPoint + "api/Region/PublicGetAll")
                    .then((response) => {
                        self.RegionList = response.data.Data;
                    })
                    .finally(() => self.isLoading = false);
            },
            LoadMapLocation: function () {
                let self = this;
                self.axios
                    .get(process.env.VUE_APP_APIEndPoint + "api/MapLocation/PublicGetAll")
                    .then((response) => {
                        self.AllMapLocationList = response.data.Data;
                        self.MapLocationList = response.data.Data;
                        self.DMapLocationList = response.data.Data;
                    })
                    .finally(() => self.isLoading = false);
            },
            FilterLocation: function (LocationType) {
                let self = this;
                self.SelectedLocationType = LocationType;
                for (let i = 0; i <= 5; i++) {
                    let element = document.getElementById('Ltype'+i);                    
                    element.classList.remove('active');
                }
                let element = document.getElementById('Ltype' + LocationType);
                element.classList.add('active');
                self.MapFilter();
            }

        },
        mounted() {
            let self = this;
           // self.loadMarkers();
            self.LoadRegions();
            self.LoadMapLocation();
            //self.geolocate();
            // As an instance method inside a component
            
            loadScript("https://maps.googleapis.com/maps/api/js?key=AIzaSyBNYpBkz9ARxGBl4wfwmxYJ2W9Mz0uaPLA&libraries=places&sensor=false&streetViewControl=false")
                .then(() => {
                  
                    // Script is loaded, do something
                    let mapOptions = {
                        center: self.center,
                        zoom: 5.5,
                        mapTypeId: google.maps.MapTypeId.ROADMAP,
                        streetViewControl: false,
                        mapTypeControl:false
                        //disableDefaultUI: true,
                    };                    
                    self.map = new google.maps.Map(document.getElementById("dvMap"), mapOptions);
                    //self.LoadMapLocation();
                    setTimeout(function () {
                        self.loadMarkers()
                    }, 2000);

                    if (self.defaultFilter) {
                        self.FilterLocation(defaultFilter);
                    }
                   

                })
                .catch(() => {
                    // Failed to fetch script
                });
        },
        computed: {
            //google: gmapApi
        },
        watch: {
            '$i18n.locale': function (newVal, oldVal) {
                let self = this;
                self.MapFilter();
            }
        }

    };
    function loadScript(src) {
        // eslint-disable-line no-param-reassign
        return new Promise(function (resolve, reject) {
            let shouldAppend = false;
            let el = document.querySelector('script[src="' + src + '"]');
            if (!el) {
                el = document.createElement("script");
                el.type = "text/javascript";
                el.async = true;
                el.src = src;
                shouldAppend = true;
            } else if (el.hasAttribute("data-loaded")) {
                resolve(el);
                return;
            }

            el.addEventListener("error", reject);
            el.addEventListener("abort", reject);
            el.addEventListener("load", function loadScriptHandler() {
                el.setAttribute("data-loaded", true);
                resolve(el);
            });

            if (shouldAppend) document.head.appendChild(el);
        });
    }

    function unloadScript(src) {
        // eslint-disable-line no-param-reassign
        return new Promise(function (resolve, reject) {
            const el = document.querySelector('script[src="' + src + '"]');

            if (!el) {
                reject();
                return;
            }

            document.head.removeChild(el);

            resolve();
        });
    }
