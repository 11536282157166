import { render, staticRenderFns } from "./UseTerms.vue?vue&type=template&id=21a1dce3&scoped=true&"
import script from "./UseTerms.vue?vue&type=script&lang=js&"
export * from "./UseTerms.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21a1dce3",
  null
  
)

export default component.exports