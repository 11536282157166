//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import EmptyState from '@/components/shared/EmptyState.vue'
import { EventBus } from '@/main.js'
import SearchContent from '@/components/shared/SearchContent.vue'


export default {
    name: "SearchContentResult",
    components: { EmptyState, SearchContent },
    data() {
        return {
            lang: localStorage.lang,
            total_pages: 0,
            total_rows: 0,
            matchedContentList: [],
            query: {
                Filter: [],
                PageNumber: 1,
                PageSize: process.env.VUE_APP_Default_Page_Size,
            },
            Filter: {},
            isLoading: false
        };
    },
    computed: {
        showLoadMore() {
            return !this.isLoading && Math.ceil(this.matchedContentList[0]?.Total / this.query.PageSize) >= this.query.PageNumber;
        }
    },
    methods: {
        formatDate(value) {
            if (localStorage.lang == 'ar') {
                let date = new Date(value);
                const day = date.toLocaleString("default", { day: "2-digit" });
                const month = date.toLocaleString("ar-EG-u-nu-latn", { month: "short" });
                const year = date.toLocaleString("default", { year: "numeric" });
                return day + " " + month + " " + year;
            }

            else if (localStorage.lang == 'en') {
                let date = new Date(value);
                const day = date.toLocaleString("default", { day: "2-digit" });
                const month = date.toLocaleString("default", { month: "short" });
                const year = date.toLocaleString("default", { year: "numeric" });
                return day + " " + month + " " + year;
            }
        },
        Search(isLoadMore) {
            //debugger;
            let self = this;
            if (!self.isLoading) {
                self.isLoading = true;
                self.axios
                    .post(process.env.VUE_APP_APIEndPoint + "api/SearchContent/PublicGetAll", self.query)
                    .then(function (res) {
                        //debugger;
                        if (res.data.Data) {
                            if (isLoadMore)
                                self.matchedContentList = self.matchedContentList.concat(res.data.Data);
                            else
                                self.matchedContentList = res.data.Data;
                        }
                        else {
                            self.matchedContentList = [];
                        }

                        self.query.PageNumber = res.data.PageNumber;
                        self.query.PageSize = res.data.PageSize;
                        self.total_rows = res.data.Total;
                        self.total_pages = Math.ceil(self.total_rows / res.data.PageSize);
                        if (self.total_pages > 0 && self.query.PageNumber <= 1) {
                            setTimeout(function () { }, 100);
                        }
                    })
                    .finally(() => self.isLoading = false);
            }
        },
        loadMore() {

            let self = this;
            if (self.showLoadMore) {
                self.Search(true);
            }
        },
        Scroll() {
            let self = this;
            self.query.PageNumber++;
            self.loadMore();
        },
        handleScroll(event) {
            let infiniteList = document.getElementById("infinite-list");
            if (infiniteList) {
                let listPosition = infiniteList.offsetTop + infiniteList.offsetHeight;

                if (window.scrollY >= listPosition - 600) {
                    this.Scroll();
                }
            }
        }
    },
    created() {

        //debugger;
        EventBus.$on('searchValue', (value) => {
            //debugger;
            let self = this;
            self.query = value;
            self.Search(false);
        });

        window.addEventListener('scroll', this.handleScroll);
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll);
    }
};
