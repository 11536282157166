import { render, staticRenderFns } from "./WorldDiabetesDay2024.vue?vue&type=template&id=1265de47&scoped=true&"
import script from "./WorldDiabetesDay2024.vue?vue&type=script&lang=js&"
export * from "./WorldDiabetesDay2024.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1265de47",
  null
  
)

/* custom blocks */
import block0 from "./WorldDiabetesDay2024.vue?vue&type=custom&index=0&blockType=link&rel=shortcut%20icon&href=https%3A%2F%2Fcpwebassets.codepen.io%2Fassets%2Ffavicon%2Ffavicon-aec34940fbc1a6e787974dcd360f2c6b63348d4b1f4e06c77743096d55480f33.ico"
if (typeof block0 === 'function') block0(component)

export default component.exports